import React from 'react';
import { COLORS } from '../../ui/variables';
import { css } from '@emotion/core';
import Button from '../../ui/BaseButton';

interface ButtonProps {
  text: string;
  href: string;
}

const ButtonCTA = ({ text, href }: ButtonProps) => (
  <Button
    type='external'
    href={href}
    color={COLORS.NEW_ORANGE}
    css={css`
      padding: 12px 26px;
      font-weight: 700;
      font-size: 14px;
      letter-spacing: 2px;
      text-transform: uppercase;
      flex-shrink: 0;
      width: fit-content;
    `}
  >
    {text}
  </Button>
);
export default ButtonCTA;
